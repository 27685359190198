import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import CustomNavbar from "./Components/Layout/Header/Navbar";
import UsersTable from "./Components/UsersTable/Table/UsersTable";
import Login from "./Components/Login/Login";
import Seetings from "./Components/Settings/Seetings";
import Footer from "./Components/Layout/Footer/Footer";
import PrivateRoutes from "./Components/Layout/PrivateRoutes/PrivateRoutes";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/users" element={<UsersTable />} />
            <Route path="/settings" element={<Seetings />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
