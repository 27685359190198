import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import CustomNavbar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import UsersTable from "../../UsersTable/Table/UsersTable";
import Seetings from "../../Settings/Seetings";

const PrivateRoutes = () => {
  return (
    <div>
      <div>
        <CustomNavbar />
      </div>
      <div>
        <Outlet />
      </div>
      {/* <div>
        <Footer />
      </div> */}
    </div>
  );
};

export default PrivateRoutes;
