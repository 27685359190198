import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteModal = ({ show, handleClose, handleDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} className="mt-5 py-5">
      <Modal.Body>
        <div className="icon-box">
          <i className="material-icons">&#xE5CD;</i>
        </div>
        <div className="text-center ">
          <h4 className="delete-modal-title mt-2">Are you sure?</h4>
          <p className="modal-text mt-2">
            Do you really want to delete these records?
          </p>
        </div>

        <div className="d-flex gap-3 align-items-center justify-content-center">
          <div>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div>
            <Button className="submit-btn" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
