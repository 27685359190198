const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllSettings = async () => {
  try {
    const response = await fetch(`${BASE_URL}/Settings/GetAllSettings`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching settings:", error);
    throw error;
  }
};

export const addSettings = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/Settings/AddSettings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`Failed to add settings! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error adding settings:", error);
    throw error;
  }
};

export const updateSettings = async (id, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/Settings/UpdateSettings/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error(`Failed to update settings! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating settings:", error);
    throw error;
  }
};

export const deleteSettings = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/Settings/DeleteSettings/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error(`Failed to delete settings! Status: ${response.status}`);
    }
    return true; // Successfully deleted
  } catch (error) {
    console.error("Error deleting settings:", error);
    throw error;
  }
};
export const getAllScheduler = async () => {
  try {
    const response = await fetch(`${BASE_URL}/Scheduler/GetAllScheduler`);
    if (!response.ok) {
      throw new Error("Failed to fetch scheduler data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error fetching scheduler data: ${error.message}`);
  }
};
export const downloadExcel = async (categoryId) => {
    try {
      const response = await fetch(`${BASE_URL}/Keepa/ExportExcel?CategoryId=${categoryId}`);
      if (!response.ok) {
        throw new Error("Failed to download Excel file");
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw new Error(`Error downloading Excel file: ${error.message}`);
    }
  };
  export const fetchDataFromAPI = async (selectedIds) => {
    try {
      const response = await fetch(
        `${BASE_URL}/Keepa/Scheduler/${selectedIds}`,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Data received successfully:", data);
        return { success: true, data };
      } else {
        console.error("Failed to fetch data:", response.statusText);
        return { success: false, error: response.statusText };
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return { success: false, error: error.message };
    }
  };