import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
  FormText,
  Form,
  Container,
} from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import "./Seetings.css";
import { message, Tooltip } from "antd";
import {
  getAllSettings,
  addSettings,
  updateSettings,
  deleteSettings,
  getAllScheduler,
} from "../Services/Api/Api";
import EditModal from "../Modal/EditModal/EditModal";
import DeleteModal from "../Modal/DeleteModal/DeleteModal";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Checkbox, Radio } from "@mui/material";
const Seetings = () => {
  const [showModal, setShowModal] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [settings, setSettings] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editable, setEditable] = useState(true);
  const [formData, setFormData] = useState({
    Category: "",
    MinBSR: "",
    MaxBSR: "",
    Month: "",
  });
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({
    Category: "",
    MinBSR: "",
    MaxBSR: "",
    Month: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    fetchData();
    const userProfile = localStorage.getItem("accessToken");
    console.log(localStorage.getItem("inProgressItems"));

    if (!userProfile) {
      navigate("/");
    }
  }, [navigate]);

  const handleClose = () => {
    setShowModal(false);
    setEditItemId(null);
    setEditFormData({
      Category: "",
      MinBSR: "",
      MaxBSR: "",
      Month: "",
    });
  };

  const handleShow = () => {
    setShowModal(true);
    setFormData({
      Category: "",
      MinBSR: "",
      MaxBSR: "",
      Month: "",
    });
  };

  const handleEdit = (id) => {
    const editItem = settings.find((item) => item._id === id);
    if (editItem) {
      setEditItemId(id);
      setEditFormData({
        Category: editItem.Category,
        MinBSR: editItem.MinBSR.toString(),
        MaxBSR: editItem.MaxBSR.toString(),
        Month: editItem.Month.toString(),
      });
      setShowModal(true);
    }
  };

  const handleDelete = async () => {
    try {
      console.log("Deleting settings with ID:", editItemId);
      setLoading(true);
      const success = await deleteSettings(editItemId);
      if (success) {
        console.log("deleted successfully!");
        message.success("Deleted successfully!");
        fetchData();
        setShowDeleteModal(false);
        setEditItemId(null);
      } else {
        console.error("Failed to delete settings");
        message.error("Failed to delete settings");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "Category") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({
      ...editFormData,
      [name]: value,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllScheduler();

        setScheduleData(response);
        const storedActions = JSON.parse(localStorage.getItem("actions"));
        console.log(storedActions);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      if (editItemId) {
        await updateSettings(editItemId, editFormData);
        console.log("updated successfully!");
        message.success("updated successfully!");
      } else {
        await addSettings(formData);
        console.log("added successfully!");
        message.success("added successfully!");
      }

      fetchData();
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update or add settings");
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const data = await getAllSettings();
      setSettings(data);
      console.log("Settings fetched successfully:", data);
    } catch (error) {
      console.error("Error fetching settings:", error);
      message.error("Something Went Wrong");
    } finally {
      setLoading(false);
    }
  };

  scheduleData.forEach((item) => {
    console.log("Action:", item.Action);
  });

  const handleAddButtonClick = async () => {
    setTooltipOpen(false);

    if (!selectedRowId) {
      message.warning("Please choose a category");
      return;
    }

    const isInProgress = scheduleData.some(
      (item) => item.Action === "InProgress"
    );
    if (isInProgress) {
      message.warning("Action is already in progress");
      console.log("Action is already in progress");
      return;
    }

    try {
      const response = await fetch(
        `https://bestsellersapi.agilensmartservices.com/Keepa/Scheduler/${selectedRowId}`,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Data received successfully:", data);
        message.success("Triggered successfully");
      } else {
        console.error("Failed to fetch data:", response.statusText);
        message.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data");
    }
  };

  const handleShowRowData = (row) => {
    if (selectedRowId === row._id) {
      setSelectedRowId(null);
    } else if (selectedRowId !== null) {
      message.warning("Trigger One Category At a Time for now");
    } else {
      setSelectedRowId(row._id);
    }
  };

  const columns = [
    {
      width: 120,
      renderCell: (params) => (
        <Radio
          checked={selectedRowId === params.row._id}
          onClick={() => handleShowRowData(params.row)}
          color="primary"
          inputProps={{ "aria-label": "Show Data checkbox" }}
        />
      ),
    },
    { field: "Category", headerName: "Category", minWidth: 200 },
    { field: "MinBSR", headerName: "Min BSR", minWidth: 200 },
    { field: "MaxBSR", headerName: "Max BSR", minWidth: 200 },
    { field: "Month", headerName: "Month", minWidth: 200 },
    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDateTime = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${(
          "0" + date.getMinutes()
        ).slice(-2)}`;
        return <span>{formattedDateTime}</span>;
      },
    },
    {
      field: "Actions",
      headerName: "Status",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEdit(params.row._id)}
            style={{ marginRight: "10px" }}
            className="button"
          >
            <svg
              className="svg-icon"
              fill="white"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="#054974" strokeLinecap="round" strokeWidth="2">
                <path d="m20 20h-16"></path>
                <path
                  clipRule="evenodd"
                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                  fillRule="evenodd"
                ></path>
              </g>
            </svg>
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDeleteModal(true);
              setEditItemId(params.row._id);
            }}
            className="button"
          >
            <svg
              className="svg-icon"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke="#054974" strokeLinecap="round" strokeWidth="1">
                <path d="m20 20h-16"></path>
                <path
                  d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z"
                  clipRule="evenodd"
                  fillRule="evenodd"
                ></path>
              </g>
            </svg>
          </Button>
        </div>
      ),
    },
  ];

  const rows = settings.map((item, index) => ({
    id: index,
    ...item,
  }));

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection.selectionModel);
    console.log(selection);
  };
  const categories = [
    "Home & Kitchen",
    "Pets supplies",
    "Health & Houseold",
    "Beauty & Personal Care",
  ];

  const handleConfirmButtonClick = (e) => {
    e.stopPropagation();

    console.log("Confirm button clicked");
  };
  const handleCancelButtonClick = () => {
    setTooltipOpen(false);
  };

  const handleOkButtonClick = () => {};
  const handleTriggerButtonClick = () => {
    setTooltipOpen(true);
  };
  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col className="d-flex justify-content-between">
          <Button className="submit-btn" onClick={handleShow}>
            <div className="d-flex align-items-center">
              <BiPlus />
              <span>Add New</span>
            </div>
          </Button>
          <Tooltip
            open={tooltipOpen}
            variant="white"
            onClose={() => setTooltipOpen(false)}
            title={
              <div
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  fontFamily: "cairo",
                }}
              >
                <p className="text-black">Are You Sure You Want to Trigger?</p>
                <Button
                  onClick={handleAddButtonClick}
                  style={{
                    fontSize: "12px",
                    marginRight: "10px",
                    background: "#054974",
                    border: "0",
                  }}
                >
                  OK
                </Button>
                <Button
                  onClick={handleCancelButtonClick}
                  style={{
                    fontSize: "12px",
                    marginRight: "10px",
                    background: "#054974",
                    border: "0",
                  }}
                >
                  Cancel
                </Button>
              </div>
            }
          >
            <Button className="submit-btn" onClick={handleTriggerButtonClick}>
              Trigger
            </Button>
          </Tooltip>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleClose} className="mt-5 py-5">
        <Modal.Body>
          <Row>
            <Col md={6}>
              <label>Category</label>
              <InputGroup className="mb-3">
                <InputGroup className="mb-3">
                  <Form.Select
                    className="form-select"
                    aria-label="Category select"
                    name="Category"
                    value={
                      editItemId ? editFormData.Category : formData.Category
                    }
                    onChange={editItemId ? handleEditChange : handleChange}
                    disabled={!!editItemId}
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </InputGroup>
            </Col>
            <Col md={6}>
              <label>Month</label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Month"
                  name="Month"
                  type="number"
                  min="0"
                  max="12"
                  value={editItemId ? editFormData.Month : formData.Month}
                  onInput={(e) => {
                    e.target.value = Math.max(
                      0,
                      Math.min(12, parseInt(e.target.value) || 0)
                    );
                  }}
                  onChange={editItemId ? handleEditChange : handleChange}
                />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <label>Minimum BSR</label>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Minimum BSR"
                  name="MinBSR"
                  value={editItemId ? editFormData.MinBSR : formData.MinBSR}
                  onChange={editItemId ? handleEditChange : handleChange}
                />
              </InputGroup>
            </Col>
            <Col md={6}>
              <label>Maximum BSR</label>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Maximum BSR"
                  name="MaxBSR"
                  value={editItemId ? editFormData.MaxBSR : formData.MaxBSR}
                  onChange={editItemId ? handleEditChange : handleChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button className="submit-btn" onClick={handleSubmit}>
                  {editItemId ? "Update" : "Save"}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <DeleteModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />

      <div className="principal-content mt-4">
        <Row>
          <Col>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div style={{ width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  hideFooter
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Seetings;
