import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import { message } from "antd";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    showPassword: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      credentials.username === "admin@gmail.com" &&
      credentials.password === "Keepa@123"
    ) {
      const accessToken = "Best Sellers_access_token_@agile";
      localStorage.setItem("accessToken", accessToken);
      navigate("/users");
    } else {
      message.error("Invalid credentials. Please try again.");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setCredentials({ ...credentials, showPassword: !credentials.showPassword });
  };

  return (
    <div className="container">
      <div className="signin-form">
        <h2> Best Sellers</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="username">Email</label>
            <input
              type="text"
              id="username"
              name="username"
              value={credentials.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="">
            <label htmlFor="password">Password</label>
            <div className="password-input input-group">
              <input
                type={credentials.showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
              />

              {credentials.showPassword ? (
                <RiEyeCloseFill
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              ) : (
                <RiEyeFill
                  onClick={togglePasswordVisibility}
                  className="eye-icon"
                />
              )}
            </div>
          </div>
          <button type="submit" className="login-btn">
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
