import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { message } from "antd";
import { downloadExcel, getAllScheduler } from "../../Services/Api/Api";
import { useNavigate } from "react-router-dom";

const UsersTable = () => {
  const [loading, setLoading] = useState(true);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [scheduleData, setScheduleData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userProfile = localStorage.getItem("accessToken");
    if (!userProfile) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllScheduler();

        setScheduleData(response);
        
        response.forEach((item) => {
          console.log(`Action: ${item.Action}`);
        });
        const storedActions = JSON.parse(localStorage.getItem("actions"));
        console.log(storedActions);

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDownload = async (categoryId) => {
    setLoadingDownload(true);
    try {
      console.log("Downloading Excel for CategoryId:", categoryId._id);
      const blob = await downloadExcel(categoryId._id);

      const timestamp = new Date()
        .toISOString()
        .replace(/[-T:.]/g, "")
        .replace("Z", "");
      const formattedTimestamp = `${timestamp.slice(0, 4)}-${timestamp.slice(
        4,
        6
      )}-${timestamp.slice(6, 8)}_${timestamp.slice(8, 10)}-${timestamp.slice(
        10,
        12
      )}-${timestamp.slice(12)}`;

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${categoryId.Category}_BestSellerData_${formattedTimestamp}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      message.success("Export file downloaded successfully!");
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      message.error("Failed to download Excel file");
    } finally {
      setLoadingDownload(false);
    }
  };

  const renderActionCell = (params) => {
    return <span>{params.value}</span>;
  };

  const renderDownloadCell = (params) => {
    if (params.row.Action === "Completed") {
      return (
        <Button
          className="button bg-white border-0"
          onClick={() => handleDownload(params.row)}
          disabled={loadingDownload}
        >
          <svg
            className="svg-icon"
            viewBox="0 0 384 512"
            height="1em"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeLinecap="round" strokeWidth="1" fill="#054974">
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              <path d="m20 20h-16"></path>
            </g>
          </svg>
        </Button>
      );
    }
    return null;
  };

  const columns = [
    { field: "Category", headerName: "Category", minWidth: 200, flex: 1 },
    {
      field: "Action",
      headerName: "Status",
      width: 150,
      flex: 1,
      renderCell: renderActionCell,
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDateTime = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${(
          "0" + date.getMinutes()
        ).slice(-2)}`;

        return;
        <span>{formattedDateTime}</span>;
      },
    },
    {
      field: "CompletedOn",
      headerName: "Completed On",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDateTime = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${date.getHours()}:${(
          "0" + date.getMinutes()
        ).slice(-2)}`;

        return <span>{}</span>;
      },
    },
    {
      field: "Download",
      headerName: "Download",
      minWidth: 200,
      flex: 1,
      renderCell: renderDownloadCell,
    },
  ];

  return (
    <Container fluid className="p-3 mt-4">
      <div className="principal-content mt-5">
        <Row>
          <Col>
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div style={{ width: "100%" }}>
                <DataGrid
                  rows={scheduleData}
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row._id}
                  hideFooter
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default UsersTable;
